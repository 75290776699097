
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '@/lib/util'
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import VesselTypeForm from './vesseltype-form.vue'
    import SortingModel from "@/store/entities/sortingmodel";

    class PageVesselTypeRequest extends PageRequest {
    }

    @Component({
        components: {VesselTypeForm}
    })
    export default class VesselType extends AbpBase {
        edit() {
            this.editModalShow = true;
        }

        async search() {
            this.$store.commit('vesseltype/setCurrentPage', 1);
            await this.getpage();
        }

        //filters
        pagerequest: PageVesselTypeRequest = new PageVesselTypeRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;

        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getpage()
        }

        async handleUpload(file) {
            let formData = new FormData();
            formData.append('file', file);
            await this.$store.dispatch({
                type: 'vesseltype/uploadFile',
                data: formData
            })
            await this.getpage();
        }

        get list() {
            return this.$store.state.vesseltype.list;
        };

        get loading() {
            return this.$store.state.vesseltype.loading;
        }

        create() {
            this.createModalShow = true;
        }

        pageChange(page: number) {
            this.$store.commit('vesseltype/setCurrentPage', page);
            this.getpage();
        }

        pagesizeChange(pagesize: number) {
            this.$store.commit('vesseltype/setPageSize', pagesize);
            this.getpage();
        }

        async getpage() {

            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'vesseltype/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.vesseltype.pageSize;
        }

        get totalCount() {
            return this.$store.state.vesseltype.totalCount;
        }

        get currentPage() {
            return this.$store.state.vesseltype.currentPage;
        }

        columns = [{
            title: this.L('Name'),
            key: 'name',
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 150,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small',
                            disabled: !this.hasEditPermission()
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: () => {
                                this.$store.commit('vesseltype/edit', params.row);
                                this.edit();
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small',
                            disabled: !this.hasDeletePermission()
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Vessel type Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'vesseltype/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            await this.getpage();
        }

        hasAddPermission(){
            return this.hasPermission("Pages.VesselTypes.Add");
        }

        hasEditPermission(){
            return this.hasPermission("Pages.VesselTypes.Edit");
        }

        hasDeletePermission(){
            return this.hasPermission("Pages.VesselTypes.Delete");
        }
    }
